import React, { Component, createRef, RefObject } from "react";
import { Classes, Drawer, Icon, Position, H2 } from "@blueprintjs/core";
import ReactToPrint from "react-to-print";

// FIXME: Once https://github.com/facebook/create-react-app/issues/11770
// is fixed, remove the !file-loader! syntax from the SVG imports (and
// consequently remove the eslint disables).

import {
    Entry, Section, EDUCATION, EXPERIENCE, PUBLICATIONS, AWARDS
  } from "./constants";
import "./App.scss";
// eslint-disable-next-line
import githubLogo from "!file-loader!./github.svg";
import orcidLogo from "./ORCIDiD_iconvector.svg";
import gscholarLogo from "./Google_Scholar_logo.svg";
import { Preface } from "./usr4002a";

interface SubsectionState {
  isOpen: boolean;
}

function App() {
  return (
    <div className="App">
      <Preface />
      <header className="header">
        <h1 className="Name">Ning Yuan</h1>
        <p className="Contact">
          <code>ningyuan&lt;at&gt;u.nus.edu</code>;&nbsp;
          <a href="https://orcid.org/0000-0002-2258-9851">
            <img src={orcidLogo} alt="ORCID logo" height="16em" /></a>,&nbsp;
          <a href="https://scholar.google.com/citations?user=08dRzK8AAAAJ">
            <img src={gscholarLogo} alt="Google Scholar logo" height="16em" /></a>,&nbsp;
          <a href="https://github.com/ning-y">
            <img src={githubLogo} alt="GitHub logo" height="16em" /></a>.
        </p>
      </header>
      <div>
        {section2html(EDUCATION)}
        {section2html(EXPERIENCE)}
        {section2html(PUBLICATIONS)}
        {section2html(AWARDS)}
      </div>
    </div>
  );
}

function section2html(section: Section) {
  return (<div className="Section" key={section.title}>
    <h2> {section.title} </h2>
    <hr />
    {section.entries.map(entry => <Subsection key={entry.title} {...entry}/>)}
  </div>)
}

class Subsection extends Component<Entry> {
  public state: SubsectionState = { isOpen: false };
  private componentRef: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();

  private handleClose = () => this.setState({ isOpen: false });
  private handleOpen = () => this.setState({ isOpen: true });

  render() {
    return (<>
      { this.props.hasOwnProperty("drawer") &&
        <Drawer
          title={<ReactToPrint
            trigger={() => <span className="clickable">{this.props.drawer!.title}</span>}
            content={() => this.componentRef.current}
            documentTitle={`Ning Yuan (A0167286R) - ${this.props.drawer!.title}`}
          /> }
          icon={this.props.drawer!.drawerIcon}
          className="abc" position={Position.RIGHT}
          onClose={this.handleClose} {...this.state} >
        <div className={Classes.DRAWER_BODY}>
          <div className={Classes.DIALOG_BODY} ref={this.componentRef}>
            <H2 className="print-only">{this.props.drawer!.title}</H2>
            {this.props.drawer!.body}
          </div>
        </div>
      </Drawer> }
      <div>
        <h3>
          {this.props.title}
          {this.props.hasOwnProperty("drawer") &&
            <span className="clickable">
              <Icon icon={this.props.drawer!.clickableIcon} onClick={this.handleOpen} />
            </span>}</h3>
          {this.props.hasOwnProperty("subtitle") &&
            <p className="subtitle"
              dangerouslySetInnerHTML={{__html: this.props.subtitle!}}></p>
          }
        <p className="body" dangerouslySetInnerHTML={{__html: this.props.body}}></p>
      </div>
    </>)
  }
}

export default App;
