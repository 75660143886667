import React from "react";
import { IconName } from "@blueprintjs/icons";
// Artefacts for "The value of subjective knowledge"
import artefactBiodiversityEmph1 from "./usr4002a-artefact-uls2208-emphasis1.jpg";
import artefactBiodiversityEmph2 from "./usr4002a-artefact-uls2208-emphasis2.jpg";
import artefactNagel from "./usr4002a-artefact-upi2208-nagel1.jpg";
import artefactHigherEd from "./usr4002a-artefact-hedr-notes.jpg";
// Artefacts for "Asking the right questions"
import artefactBiol395 from "./usr4002a-artefact-biol395-reviewed.png";
import artefactLsm3211Wrong from "./usr4002a-artefact-lsm3241-wrong.png";
import artefactLsm3211Right from "./usr4002a-artefact-lsm3241-slack-and-correct.png";
import artefactZb4199 from "./usr4002a-artefact-zb4199-simulation-evaluation.png";
// Artefacts for "Conscious reframing"
import artefactSourceAcademy from "./usr4002a-artefact-source-academy.jpg";
import artefactPalantir from "./usr4002a-artefact-palantir.gif";
// Artefacts for "Learning from the literature"
import artefactEnclosurePhoto from "./usr4002a-artefact-uis3901s-photo.jpg";
import artefactProposal from "./usr4002a-artefact-uis3901s-proposal.jpg";

export interface Section {
  readonly title: string;
  readonly entries: ReadonlyArray<Entry>;
}

export interface Entry {
  readonly title?: string;
  readonly subtitle?: string;
  readonly body: string;
  readonly drawer?: DrawerProperties;
}

export interface DrawerProperties {
  readonly clickableIcon: IconName;
  readonly drawerIcon: IconName;
  readonly title: string;
  readonly body: JSX.Element;
}

export const theValueOfSubjectiveKnowledge: DrawerProperties = {
  clickableIcon: "citation",
  drawerIcon: "citation",
  title: "The value of subjective knowledge",
  body: <>
    <p>
      I entered university with a naive view of knowledge: I thought that <i>all</i> phenomena could be explained by physical processes, assuming enough objective knowledge &mdash; facts whose truths do not rely on individual perception or interpretation.
      Subjective knowledge, which can be influenced by individual perception, only seemed subjective because we have not yet fully discovered all of the physical mechanisms underlying them.
      They are placeholders for objective knowledge, only useful for as long as it takes to understand their physical causes.
      If we did, then we could explain away the subjectivity using objective terms.
    </p>
    <p>
      I was also certain that as far as possible, <i>any</i> knowledge should be obtained through the scientific method (observation, hypothesis, prediction, and testing), preferably using the tools of statistics.
      Only then can we be certain that we remain as objective as possible &mdash; otherwise, we risk producing biased results which cannot be meaningful generalised outside of the sample of study. I echo this view in a piece of writing for the class <a href="https://nusmods.com/modules/ULS2208/"><i>Biodiversity</i> (ULS2208)</a>, which I read at the end of my first year.
    </p>
    <img className="artefact" src={artefactBiodiversityEmph1} alt="Some writing I had produced for Biodiversity (ULS2208). I have highlighted the clause of interest in orange, at the present time of writing." />
    <p className="caption">
      Some writing I had produced for <i>Biodiversity</i> (ULS2208).
      I have highlighted the clause of interest in orange, at the present time of writing.
    </p>
    <p>
      "Statistics is how we make sense of empirical data", I wrote.
      It is <i>the</i> one way to use data.
      I thought that qualitative analysis should be reserved as the last resort, to be used only if a data set was unsuitable for quantitative methods.
    </p>
    <p>
      Fortunately, I have since had a number of learning experiences which have showed me how narrow this mindset was.
      Here, I want to focus on two of them in particular: the first from the class <a href="https://nusmods.com/modules/UPI2208/"><i>Imagining Animals</i> (UPI2208)</a>, and the second from a research project about critical thinking and higher education.
    </p>
    <p className="section">Learning to be subjective</p>
    <p>
      I took <i>Imagining Animals</i> one year after <i>Biodiversity</i>.
      In it, I read Thomas Nagel's (1974) seminal essay "What Is It Like to Be a Bat", which became one of the few assigned readings in university which would stick with me even after a module's conclusion (sorry!)
    </p>
    <img className="artefact" src={artefactNagel} alt={"An excerpt from Nagel's (1974) \"What Is It Like to Be a Bat\", an assigned reading for <i>Imagining Animals</i> (UPI2208). I had made those highlights and annotations when I was reading this passage for the first time."} />
    <p className="caption">
      An excerpt from Nagel's (1974) "What Is It Like to Be a Bat", an assigned reading for <i>Imagining Animals</i> (UPI2208).
      I had made those highlights and annotations when I was reading this passage for the first time.
    </p>
    <p>
      In his essay, Nagel argues (among other things) that consciousness possesses an inherent subjective character which cannot be explained through objective means.
      Though I may not fully understand the intricacies of his essay, Nagel's argument was my first serious encounter with the idea that there were indeed subjective phenomena which were irreducible to objective facts, even if we had a complete understanding of all the physical mechanisms related to that subjective phenomena.
      Furthermore, it led me to seriously consider the possibility that trying to place subjective phenomena under objective scrutiny &mdash; for example, through statistical methods which I held so highly in regard &mdash; might be counterproductive, since doing so would ignore all the individual richness and complexities of each experience (which is a key characteristic of subjective experiences).
      Qualitative analysis then, is not the the last resort I thought it was, but the most appropriate method of investigation for the subjective.
    </p>
    <p>
      In the semester after <i>Imagining Animals</i>, I was able to turn this understanding into appreciation, when I volunteered as one of three student researchers in a project investigating critical thinking development in higher education.
      The project was to be a qualitative analysis of NUS students' subjective experiences of their critical thinking development in higher education.
      Had I not just read Nagel, I imagine that I would have been quite uncomfortable trying to generalise a single qualitative case study of just NUS students to students in general.
      Perhaps I would have suggested to collaborate or send out surveys to other universities, just to make sure that we could be as objective as possible in our study of subjective experience.
    </p>
    <img className="artefact" src={artefactHigherEd} alt={"An extract from a set of notes provided by our research supervisors to introduce us to the methodology. I had made those annotations and notes while they were teaching us about case study methodology. On the right, I wrote: Other forms of knowledge other than scientific. \"good case studies are those you can learn from\"."} />
    <p className="caption">
      An extract from a set of notes provided by our research supervisors to introduce us to the methodology.
      I had made those annotations and notes while they were teaching us about case study methodology.
      On the right, I wrote: Other forms of knowledge other than scientific. "good case studies are those you can learn from".
    </p>
    <p>
      Instead, I found myself in agreement with this method of qualitative analysis proposed by our research supervisors.
      I understood that although the objective facts of a case study may not generalise to other cases, the subjective experiences which we provide and make meaning of can inspire others to interpret their own experiences in a different way.
      The manuscript for this research project is currently under review.
    </p>
    <p className="section">Subjectivity in quantitative research</p>
    <p>
      Recounting these learning experiences has made we wonder what I can learn about subjective knowledge in relation to my major, computational biology.
      In particular, revisiting the <i>Biodiversity</i> writing, I can now identify one more misconception which I had held.
    </p>
    <img className="artefact" src={artefactBiodiversityEmph2} alt={"The same writing as above, which I had produced for <i>Biodiversity</i> (ULS2208). I have highlighted a different clause of interest in orange, also at the present time of writing."} />
    <p className="caption">
      The same writing as above, which I had produced for <i>Biodiversity</i> (ULS2208).
      I have highlighted a different clause of interest in orange, also at the present time of writing.
    </p>
    <p>
      That misconception is my strict adherence to the dichotomy of statistical significance versus insignificance.
      Back then, when I was writing this piece for <i>Biodiversity</i>, I thought that <i>&alpha;</i> (which is usually 0.05) was the strict threshold for <i>p</i>-values in order for a statistical result to be considered 'significant'.
      Of course, I understood that <i>&alpha;</i> was an arbitrary value &mdash; but I thought that setting this arbitrary significance threshold was a 'necessary evil' of science, and that it should guarantee that only <i>&alpha;</i> proportion of discoveries within a field would be false positives, in the long run (when we are all dead).
    </p>
    <p>
      However, this strict paradigm of hypothesis testing is deeply flawed.
      I had learnt just last year from McShane et al. (2019) why this is so, but as with Nagel (1974), such a discussion is outside the scope of this blog post.
      I will focus on just one problem addressed in the aforementioned article: if <i>&alpha;</i> is large (and 0.05 is considered large), then it is not difficult for an obviously false conclusion to have <i>p &lt; &alpha;</i>; but under the strict paradigm of hypothesis testing, we would have no choice but to accept that obviously false conclusion as being 'significant'.
      One could fix this by making <i>&alpha;</i> smaller &mdash; but that would make it very difficult for any conclusion to have <i>p &lt; &alpha;</i>, unless the sample size was very large; but not all experiments are worth that much funding to collect that many samples.
      A reasonable compromise might be to select a value for <i>&alpha;</i> based upon the context of the experiment, but that is not possible if we are to strictly adhere to the hypothesis testing paradigm within our given field (the peer reviewers would not be happy!)
      So what then?
    </p>
    <p>
      So, we abandon the notion of <i>&alpha;</i> being a strict threshold for the binary validity of statistical results.
      Instead, <i>p</i> is simply used to measure the continuous degree of confidence which one should place in those results.
      In fact, most investigators already practice this 'contextualisation' of <i>p</i> values.
      Although they recognise <i>"p &lt; &alpha;"</i>, they do not strictly adhere to it: competent investigators will reject obviously false conclusions with <i>p &lt; &alpha;</i> if they know that there are flaws with the experimental design possibly explaining those false conclusions, or if they think that there was no possible mechanism which could lead to that conclusion; if they obtain <i>p</i> close to but not less than &alpha;, they might justify that as a possibly significant result based on prior research in the field suggesting a plausible causal mechanism supporting that conclusion.
    </p>
    <p>
      I had not realised it reading McShane et al. (2019) back then, but after writing this reflection, I now realise that all of these considerations are in fact a kind of qualitative analysis using subjective knowledge; qualitative analysis because each conclusion are subject to the individual's interpretation, and subjective knowledge because each individual holds different beliefs about the validity of experimental designs, state of the literature, what counts as a plausible mechanism, and so on.
      So, even in empirical research of physical phenomena, even if we are working with only quantitative methods, we inevitably still make use of subjective knowledge to inform our investigation and interpret statistical results.
      Claiming a strict adherence to <i>"p &lt; &alpha;"</i> represents at best a willful ignorance of this fact, and at worst denies the existence of any implicit human biases, leaving them unchecked.
      It turns out that the solution to the <i>"p &lt; &alpha;"</i> problem is to admit the value of subjective knowledge in quantitative research, so that it can be used reasonably and with care in interpreting quantitative results.
    </p>
    <hr />
    <p>Nagel, Thomas. 1974. ‘What Is It Like to Be a Bat?’ The Philosophical Review 83 (4): 435–50. https://doi.org/10.2307/2183914.</p>
    <p>McShane, Blakeley B., David Gal, Andrew Gelman, Christian Robert, and Jennifer L. Tackett. 2019. ‘Abandon Statistical Significance’. The American Statistician 73 (sup1): 235–45. https://doi.org/10.1080/00031305.2018.1527253.</p>
  </>
}

export const askingTheRightQuestions: DrawerProperties = {
  clickableIcon: "geosearch",
  drawerIcon: "geosearch",
  title: "Asking the right questions",
  body: <>
    <p>
      I think that it is important to ask questions.
      Asking questions helps me uncover gaps in my understanding, so that I can fill them in when I answer those questions.
      Sometimes, these questions guide me toward new concepts and ideas which I would otherwise never have encountered.
      Questions are also critical for reflection: they motivate new reflections, while also guiding the reflection process itself.
      This habitual questioning has been with me for some time now, predating my undergraduate career.
    </p>
    <p>
      In the past, most of my questions were easy to answer &mdash; I would simply Google them and read off the first search result.
      Since I began my undergraduate studies, however, the questions that occur to me have become increasingly difficult such that they usually require much more time to properly address (some of them may not even have answers!) I quickly found myself with too many questions, and too little time to address them all.
      So, it became important to consider: which questions should I ask<sup>1</sup>?
    </p>
    <p className="section">Questioning plots</p>
    <p>
      The first time I tried to seriously answer this meta-question of 'which question to ask' was during the Summer break after my second year.
      I was on an overseas Summer research internship at UNC Chapel Hill where I was tasked to update and improve upon an old pipeline for RNA structure prediction.
      During a weekly meeting, I was presenting a plot showing the relative performance of my improved program (bedshape) versus the old one (quickshape/quickshape-padded) when my research supervisor asked a question which took me completely by surprise: Why is there a dip in the middle of the plot?
    </p>
    <img className="artefact" src={artefactBiol395} alt={"The comparison plot I had presented at my UNC Chapel Hill overseas Summer research internship. I had made the annotations in blue during the meeting after my research supervisor had asked about it, as a mental note to investigate it afterwards."} />
    <p className="caption">
      The comparison plot I had presented at my UNC Chapel Hill overseas Summer research internship.
      I had made the annotations in blue during the meeting after my research supervisor had asked about it, as a mental note to investigate it afterwards.
    </p>
    <p>
      I had absolutely no idea! Even though I had prepared this plot myself, the steep decline located right in the middle of it had completely escaped my notice.
      I admitted that I did not know the answer to his question, and we moved on.
      After the meeting, however, I reflected upon that moment, and decided that if I ever wanted to be as good a scientist as my research supervisor, I had read plots as critically as he did.
      I set a rule for myself from that point onward, that I should ask more questions about any and all plots I come across.
    </p>
    <p className="section">Questioning results</p>
    <p>
      I put this rule to practice in my next Summer break after my third year, when I was working on a continuation of a class project I had undertaken in <a href="https://nusmods.com/modules/LSM3241/"><i>Genomic Data Analysis</i> (LSM3241)</a>.
      I was trying to see if RNA expression microarrays could be quantified by pseudoalignment of probe sequences, instead of using the manufacture provided mapping of probesets to genes.
      The method to beat was the popular R/bioconductor package <a href="http://bioconductor.org/packages/release/bioc/html/limma.html"><i>limma</i></a>.
      This time round, I put a lot of deliberate thought into producing the comparison plot, asking as many questions as I could about the plot I was producing, and subsequently adding the information that would help answer them.
      The result was a rather crowded plot with a common y-axis, y = x line, a linear regression line, summary statistics, and I even encoded some information into the colour of the points.
      When I was done, I had produced a plot which showed clearly that the new pseudoalignment method outperforms limma.
      I sent it off on slack, and went to bed content with my work.
    </p>
    <img className="artefact" src={artefactLsm3211Wrong} alt={"The comparison plot I first produced for limma and the new pseudoalignment method (mallisto). Because the r2 (r.squared to solid) of mallisto is much better than that of limma, I concluded that mallisto outperforms limma."} />
    <p className="caption">
      The comparison plot I first produced for limma and the new pseudoalignment method (mallisto).
      Because the r<sup>2</sup> (r.squared to solid) of mallisto is much better than that of limma, I concluded that mallisto outperforms limma.
    </p>
    <p>
      While reviewing my work the next morning, however, I realised that I had made a catastrophic mistake.
      In producing the limma results used in the plot, I accidentally used the background probes (noise) instead of the normal probes (signal).
      This caused limma to perform much worse than it normally would, invalidating my comparison.
      I sent out an apology on Slack, and quickly went to work fixing my mistake.
    </p>
    <img className="artefact" src={artefactLsm3211Right} alt={"(A) I sent the first plot on Slack, on 14 May, and deleted it on 15 May with an apology then followed-up with a description of the amendments I had made. (B) The amended version of the comparison plot. Because the r2 of both methods are comparable this time round, the coefficient (coeff) becomes the important metric of comparison. Since limma has a coefficient that is closer to 1, it outperforms mallisto."} />
    <p className="caption">
      (A) I sent the first plot on Slack, on 14 May, and deleted it on 15 May with an apology then followed-up with a description of the amendments I had made.
      (B) The amended version of the comparison plot.
      Because the r<sup>2</sup> of both methods are comparable this time round, the coefficient (coeff) becomes the important metric of comparison.
      Since limma has a coefficient that is closer to 1, it outperforms mallisto.
    </p>
    <p>
      After the amendment, it was clear that the opposite conclusion was true: the new method, mallisto, performs much worse than limma.
      But all this happened despite all the questions I had asked about the plot, because I had made mistakes in obtaining the results to be plotted, which was a prior and independent process to producing the plot.
      Clearly, I thought, my rule of always asking questions about plots were not enough.
      I added a new rule, that I would also need to start asking questions about any and all results I produce.
    </p>
    <p className="section">Questioning questioning</p>
    <p>
      Looking back on these blunders now, I realise that my reflections were not entirely productive.
      After all, if I kept accumulating these absolute rules about having to ask more questions about certain things all the time, then I would eventually just regress to the original problem of 'too little time for too many questions'.
      Rather, the meta-question of 'which question to ask' is not one which can be answered simply by setting general rules or guidelines.
      It is itself a complex question which must be seriously considered in real time, taking into consideration the current context and its needs.
      I think I was at least partly aware of this, in my subconscious:
      After all, I did not <i>really</i> bother to question absolutely all the plots I came across, or all the results I produced, if those plots are simply or the results obviously make sense.
      Nonetheless, there is value in discovering and stating it explicitly, as it becomes possible to consciously apply and practice this skill.
    </p>
    <p>
      In fact, this awareness is already proving itself useful in my current Final Year Project (FYP) where yet again, I am developing an improvement on an older method.
      In order to benchmark my method against other ones, I need to simulate bulk RNA-seq data.
      However, my simulations do not match up exactly with data derived from empirical bulk RNA-seq measurements.
    </p>
    <img className="artefact" src={artefactZb4199} alt={"Plots comparing my simulated data with data from empirical measurements. Of note are subplots (A) and (C) which should a different distribution for empirical (two leftmost) versus simulated (two right most) data."} />
    <p className="caption">
      Plots comparing my simulated data with data from empirical measurements.
      Of note are subplots (A) and (C) which should a different distribution for empirical (two leftmost) versus simulated (two right most) data.
    </p>
    <p>
      When I had first produced these simulations, they were much worse than what is shown in the plots above.
      So, I set in my mind the question, "how can I make these simulations better match up with the empirical data?"
      Even though I have since improved the simulations to be somewhat similar to empirical bulk RNA-seq measurements (demonstrated in the plots above, though there are some differences in subplots A and C), it would have been easy to continue with the aforementioned question and devote even more time into try to simulate even more accurate data.
      Instead, using the present reflection, I realise that that is not the most important question to ask, since the situation has changed &mdash; the simulations have gone from terrible to passable.
      Since my FYP is not a project about simulating data &mdash; its focus is on developing a new method; the simulation is merely a means to test that method &mdash; I changed my question to, "is this simulation close enough to the empirical one for my purposes?"
      And that answer is "yes".
      The awareness of having to ask the right questions helps me focus on working toward the main goal of my FYP, saving me the time I would have otherwise spent chasing something tangential.
    </p>
    <p>
      To be honest, I don't really enjoy having to develop or use this new skill.
      Part of me misses that childish and almost carefree curiosity, being able to ask whichever question I liked, regardless of the circumstance.
      Nonetheless, all men are mortal &mdash; time is a limited resources which must carefully rationed.
      Being able to find the right questions to ask is a necessary and important skill in being a good scientist &mdash; I'm just glad that I was able to discover this early, so that I have the time to practice it.
    </p>
    <hr />
    <p><sup>1</sup> Because question asking is sometimes an automatic process, this is more accurate phrased as "which questions should I ask <i>and subsequently address</i>?"
    However, I have opted to shorten "ask and address" as simply "ask", firstly for brevity and secondly because a question which is asked but never addressed is largely an uninteresting one.</p>
  </>
}

export const consciousReframing: DrawerProperties = {
  clickableIcon: "refresh",
  drawerIcon: "refresh",
  title: "Conscious reframing",
  body: <>
    <p>
      I undertook my first internship with the NUS School of Computing where I collaborated with a small team of undergraduate peers to develop Source Academy, a companion website for the <a href="https://nusmods.com/modules/CS1101S/">introductory programming module CS1101S</a>.
      This was the same introductory programming module I read in my first semester (that’s how I knew of this opportunity in the first place) so my internship supervisor was also my "ex-lecturer".
    </p>
    <p>
      The first half of my internship was quite enjoyable.
      I was learning a lot about programming and web development, since I had never tried building a website of that scale before.
      My colleagues were all friends of mine, and the work felt purposeful.
    </p>
    <p>
      The second half of the internship, however, was much more stressful.
      This stress came quite suddenly, and I distinctly remember its catalyst being a biweekly meeting with my supervisor.
      In that meeting, I described our progress, the technical challenges we had overcome, and the issues which we had planned to tackle before our next meeting.
      I was taken aback by my supervisor’s response: they said that they didn’t need to know the details of our work, only if it could be completed in time.
      Just from that interaction, I felt much more stress in that internship, and it became much less enjoyable as a result.
    </p>
    <img className="artefact-portrait" src={artefactSourceAcademy} alt={"An Instagram story I posted near the end of my internship. The text reads \"I can't wait to be a student again lmao\", \"miss me with this work shit\"."} />
    <p className="caption">
      An <i>Instagram</i> story I posted near the end of my internship.
      The text reads "I can't wait to be a student again lmao", "miss me with this work shit".
    </p>
    <p className="section">A diagnosis</p>
    <p>
      I had always attributed this transition to the realisation that my supervisor was less willing to guide us in the internship than I had expected.
      I thought that because they used to be my lecturer, that I expected a similar teacher-student guidance in the internship, in the same manner as when I had earlier read CS1101S (of course, they where under no such obligation &mdash; this is a <i>me</i> problem).
      I understood the stress as a result of feeling as if I had lost a "safety net" of potential guidance from my supervisor.
      As I compare this experience to others I’ve had, however, I think there may be a more satisfying explanation.
    </p>
    <img className="artefact-portrait" src={artefactPalantir} alt={"An animated showcase of the Android application visualising proteins in augmented reality which I had written for an independent research project."} />
    <p className="caption">
      An animated showcase of the Android application visualising proteins in augmented reality which I had written for an independent research project.
    </p>
    <p>
      About a year after that internship, I undertook an independent research project to develop an Android application to visualise proteins in augmented reality (AR).
      This research project was similar in many aspects to that second half of my internship: I just as inexperienced in writing AR applications as I was writing websites on the scale of Source Academy, and I also had no expectation that my research supervisor would provide any guidance in the project (understandably so, because AR Android development is a rather niche area).
      Yet I did not feel the same stress in this independent research project as I did in my internship.
      Why is that so?
    </p>
    <p>
      Looking back at the two experiences now, I think that I was able to maintain a positive attitude (leading to a less stressful experience) in the research project because I saw it primarily as a learning opportunity.
      This was also my impression of the first half of my internship.
      In the internship, however, that one biweekly meeting caused me to inadvertently reframe the internship from learning opportunity to contractual obligation.
      My focus shifted away from what I could personally learn from the experience, to my responsibility towards others to deliver results.
      This reframing, I think, is a more accurate explanation of that transition from an enjoyable
and even fun first half of the internship to a much more stressful second half.
    </p>
    <p className="section">Moving forward</p>
    <p>
      But reframing is a matter of perspective, and multiple perspectives are not necessarily exclusive of each other.
      In hindsight, I could have enjoyed the second half of my internship more if I had held on to my first impression of it being a learning opportunity more consciously, even after realising after the meeting that I had a responsibility to others in producing deliverables in a timely fashion.
      Though I obviously cannot change the past, I think that I should be able to bring this strategy of "conscious reframing" to my future endeavours.
    </p>
  </>
}

export const learningFromTheLiterature: DrawerProperties = {
  clickableIcon: "tree",
  drawerIcon: "tree",
  title: "Learning from the literature",
  body: <>
    <p>
      I undertook my second independent research project, titled "Do house plants regulate particulate matter?" at the start of my third year.
      Together with a friend from the Faculty of Engineering, we asked if house plants could <i>directly</i> regulate airborne particulate matter (PM) through biological processes, not just through physical processes such sedimentation on leaf surfaces.
      We imagined, for example, that PM collected in the intercellular air spaces could be somehow broken down by the plant cells lining those spaces.
      To that end, we set up airtight enclosures, generated particulate matter by burning dried plant matter, and compared the concentrations of airborne PM with time between enclosures containing live plants versus those containing plants which have been dried and pressed.
    </p>
    {/* Alt text is different from p.caption because of competing rules in eslint's img-redundant-alt versus writing style. */}
    <img className="artefact" src={artefactEnclosurePhoto} alt={"Our experimental set-up. Each acrylic box is an airtight enclosure with an airborne particulate matter (PM) sensor. The PM sensors are connected to a central Raspberry Pi computer with custom software to record the PM concentrations in each enclosure every five minutes."} />
    <p className="caption">
      A photo of our experimental set-up.
      Each acrylic box is an airtight enclosure with an airborne particulate matter (PM) sensor.
      The PM sensors are connected to a central Raspberry Pi computer with custom software to record the PM concentrations in each enclosure every five minutes.
    </p>
    <p className="section">Biological processes are all mediated by physical ones</p>
    <p>
      The project was successful as an <a href="https://nusmods.com/modules/UIS3901S/"><i>Independent Study Module</i> (UIS3901S)</a>, and we tried submitting this work for peer-reviewed publication.
      However, after a few rounds of rejection and subsequent reflection on the experiment, we decided against further efforts at publishing this work, as it became clear that there was a mismatch between the research question and methodology: our experiment was simply not suited to answer the initial question.
    </p>
    <p>
      It had not occurred to us that in order for the plant to directly interact with PM in any kind of "biological process", the PM must first come into physical contact with the plant.
      This process of PM physically contacting the plant, or sedimentation, removes the PM from the air and is therefore easily inferred by a decrease in airborne PM as measured by our PM sensors.
      However, that is <i>all</i> we can infer from the PM sensors &mdash; they cannot tell us anything that happens after physical sedimentation (because they only measure the air), so our experiments cannot reasonably conclude anything about the biological processes in our research question.
    </p>
    <p className="section">In hindsight</p>
    <p>
      I had only realised this mismatch in writing the introduction section of our semester-end final report, when I was reviewing the literature and justifying the importance of our research in the context of other studies.
      But, that was not the first time I had reviewed the literature or justify the novelty of this study &mdash; I had done all that before in the initial research proposal at the start of the semester.
      So, why didn't I notice this mismatch before, when writing that initial research proposal?
    </p>
    <p>
      In hindsight, I think some of the delayed realisation can definitely be attributed to gaining some "distance" from the idea throughout the length of the semester.
      Additionally (and more interestingly), I can identify some differences in the way I presented and perceived other scholarly literature in the proposal I had written at the start of the semester versus in the final report at the end of the semester.
    </p>
    <img className="artefact" src={artefactProposal} alt={""} />
    <p className="caption">
      The introduction from my proposal of this research project.
      In the highlighted sentence, I justified what I believed to be the novelty of this project in the context of other studies in the literature.
    </p>
    <p>
      In particular, when I was writing the research proposal, I primarily saw other scholarly literature as competition.
      I would search the literature mainly to check that an idea was novel, or to imitate someone else's methodology (with citation!)
      It was only in writing the report, when I started to experience some sense of cognitive dissonance between the research question and methodology, that I started to view the literature as a thing to learn from.
      Only then was I able to clearly dissect this mismatch between research question and methodology.
    </p>
    <p>
      Currently, I feel as though that first "antagonistic" approach to literature is still my default mindset.
      Yet, the second approach is a clearly more sustainable and effective way to approach scholarly literature.
      To this end, further work is needed to shift my default approach to literature from the former to latter mindset.
    </p>
  </>
}

export const EDUCATION: Section = {
  title: "Education",
  entries: [
    { title: "National University of Singapore",
      subtitle: "Fall 2017 – Projected Spring 2021",
      body: "B.Sc. (Hons.), Major in Computational Biology, " +
        "University Scholars Programme (USP). Current CAP: 4.80/5.00" },
    { title: "University of North Carolina, Chapel Hill",
      subtitle: "Summer 2019",
      body: "Summer Science Research Programme at the Laederach Lab. " +
        "GPA: 4.0/4.0" }
  ]
}

export const PUBLICATIONS: Section = {
  title: "Publications",
  entries: [
    { body: "<u>Ning Yuan Lee</u>&ast;, Zijun Wang, and Bernice Lim (2020). " +
        "\"The development of critical thinking: " +
        "What university students have to say\". Under review." },
    { body: "<u>Ning Yuan Lee</u> and Greg Tucker-Kellogg&ast; (2020). " +
        "\"An accessible, open-source mobile application for macromolecular " +
        "visualization using augmented reality\". " +
        "<span style='font-variant:small-caps'>In:</span> " +
        "<i>Biochem. Mol. Bio. Edu.</i> " +
        "<span style='font-variant:small-caps'>Doi:</span> " +
        "<a href='https://doi.org/10.1002/bmb.21335'>10.1002/bmb.21335</a>." }
  ]
}

export const EXPERIENCE: Section = {
  title: "Experience",
  entries: [
    { title: "Student Researcher at NUS' Greg-Tucker Kellogg Lab",
      subtitle: "Spring 2020 - Spring 2021",
      body: `<ul>
        <li>Developed an R package using p-value aggregation to infer differential transcription factor activation from bulk RNA-seq data</li>
        <li>Explored methods of bimodal gene analysis from single-cell RNA-seq data</li>
        <li>Explored the use of pseudoalignment in microarray data</li>
      </ul>`,
      drawer: askingTheRightQuestions },
    { title: "Student Researcher for a Higher Education Research",
      subtitle: "<b>Fall 2019 - Fall 2020</b> with A/P Johan Geertsema and Prof Tony Harland (<abbr title=\"University of Otago\">UO</abbr>)",
      body: `<ul>
        <li>Planned, executed, and analysed semi-structured interviews with two other student researchers to investigate how students develop critical thinking</li>
        <li>Wrote and submitted an article describing this research and its results for peer review (Lee, Wang, and Lim 2020)</li>
      </ul>`,
      drawer: theValueOfSubjectiveKnowledge },
    { title: "Independent Research Project (Phytoremediation)",
      subtitle: "<b>Fall 2019</b> with Dr Edmund Low",
      body: `<ul>
        <li>Conceptualised and executed an empirical study with a student peer to measure the effect of live versus dead plants on airborne particulate matter</li>
        <li>Designed and develop custom software with a Telegram bot interface to record and upload particulate matter concentration data to Google Sheets</li>
        <li>Wrote and pushed a preprint on engrXiv describing this experiment and its results: <a href="https://doi.org/10.31224/osf.io/yaude">https://doi.org/10.31224/osf.io/yaude</a></li>
      </ul>`,
      drawer: learningFromTheLiterature},
    { title: "Student Researcher at UNC's Laederach Lab",
      subtitle: "<b>Summer 2019</b>",
      body: `<ul>
        <li>Developed programs <a href="https://bedshape.ningyuan.io/"><i>bedshape</i></a> and <a href="https://trcls.ningyuan.io/"><i>trcls</i></a> as part of the lab's data analysis pipeline for RNA structure inference</li>
        <li>Documented each program clearly on its own website</li>
      </ul>`,
      drawer: askingTheRightQuestions },
    { title: "Independent Research Project (Augmented Reality)",
      subtitle: "<b>Spring 2019</b> with Prof Greg Tucker-Kellogg",
      body: `<ul>
        <li>Conceptualised and developed an Android application to visualise proteins in augmented reality</li>
        <li>Wrote and published a peer review article describing this application (Lee and Tucker-Kellogg 2020)</li>
      </ul>`,
      drawer: consciousReframing },
    { title: "Teaching Assistant, School of Computing",
      subtitle: "<b>Since Fall 2018</b>",
      body: `<ul>
        <li>Taught tutorials, graded assignments, and attended to consultations for introductory programming modules CS1010S, CS1010E (Python 3); and CS1101S (JavaScript)</li>
        <li>Advised on syllabus, pedagogy, and programming language design</li>
      </ul>` },
    { title: "Teaching Assistant, Quantitative Reasoning Centre, USP",
      subtitle: "<b>Fall 2018 - Spring 2020</b>",
      body: `<ul>
        <li>Attended to student consultations on statistics and quantitative reasoning</li>
        <li>Organised and held introduction to programming workshops</li>
      </ul>`},
    { title: "Full-Stack Web Development, NUS School of Computing",
      subtitle: "<b>Summer 2018</b> <span>with A/P Martin Henz</span>",
      body: `<ul>
        <li>Designed and developed Source Academy, a companion website/online IDE for the introductory programming module CS1101S, together with a team of student peers</li>
        <li>Deployed Source Academy on AWS</li>
        <li>Advocated for Source Academy to be open-source software &mdash; several features and fixes have been added since, with the frontend repository receiving 500 pull requests thus far</li>
      </ul>`,
      drawer: consciousReframing }
  ]
}

export const AWARDS: Section = {
  title: "Awards",
  entries: [
    { title: "Faculty of Science Dean's List",
      subtitle: "Spring 2020",
      body: "For academic performance in the Spring semester" },
    { title: "USP Student Grant",
      subtitle: "Fall 2019",
      body: "For expenses in the independent research project (phytoremediation)" },
    { title: "USP Honour Roll, Senior Honour Roll",
      subtitle: "2018, 2019",
      body: "\"For meritorious academic performance and contributions, " +
        "and achievements beyond the classroom\"" },
    { title: "Lim Soo Peng Book Prize",
      subtitle: "2019",
      body: "\"Best student in Computer Science stream in the " +
        "Science II Examination for the degree of Bachelor of Science\"" }
  ]
}