import React, { Component, createRef, RefObject } from "react";
import { Classes, Dialog, H2, Icon } from "@blueprintjs/core";
import ReactToPrint from "react-to-print";

const prefaceText = <div className={Classes.DIALOG_BODY}>
  <p>
    I produced this ePortfolio at the end of 2020 as an assignment for the module <a href="https://nusmods.com/modules/USR4002A/critical-reflection"> <i>Critical Reflection</i> (USR4002A)</a>.
    The framework for this ePortfolio is the curriculum vitae (CV), with a twist: clicking on the icons (e.g. <Icon icon="geosearch" />) in the "Experience" section will display a "drawer" elaborating and reflecting on that experience.
    Each reflection is represented by a unique icon, so experiences with the same icon are connected by the reflection represented by that icon.
    In this manner, I can satisfy the expectations and conventions of job or graduate school applications in biology research, while still having sufficient space to elaborate on my relevant research experiences.
  </p>
</div>

interface PrefaceState {
  isOpen: boolean;
}

export class Preface extends Component {
  public state: PrefaceState = { isOpen: true };
  private componentRef: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();

  private handleClose = () => this.setState({ isOpen: false });

  render() {
    return (
      <Dialog title={<ReactToPrint
          trigger={() => <span className="clickable">About this ePortfolio</span>}
          content={() => this.componentRef.current}
          documentTitle={"Ning Yuan (A0167286R) - About this ePortfolio"} />}
        onClose={this.handleClose}
        {...this.state}>
        <div ref={this.componentRef}>
          <H2 className="print-only">About this ePortfolio</H2>
          { prefaceText }
        </div>
      </Dialog>
    )
  }
}

